import { useCallback, useState } from "react";

const useToken: () => {
  setToken: (token: string) => void;
  getToken: () => string | null;
  removeToken: () => void;
} = () => {
  const [token, changeToken] = useState(localStorage.getItem("authToken"));

  const setToken = useCallback((currentToken: string) => {
    localStorage.setItem("authToken", currentToken);
    changeToken(currentToken);
  }, []);

  const getToken = useCallback(() => {
    return token;
  }, [token]);

  const removeToken = useCallback(() => {
    localStorage.removeItem("autToken");
  }, []);

  return { setToken, getToken, removeToken };
};

export default useToken;
