import React, { useEffect } from "react";

const Logout: React.FunctionComponent = () => {
  useEffect(() => {
    localStorage.removeItem("authToken");
    window.location.replace("/login");
  }, []);

  return <></>;
};

export default Logout;
