import React from "react";
import GridLoader from "react-spinners/GridLoader";
import "./LazyLoading.scss";

const LazyLoading: React.FC = () => {
  return (
    <div className={"lazy-loading__container"}>
      <GridLoader size={20} color={"#254d32"} />
    </div>
  );
};

export default LazyLoading;
