import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useToken from "../../hooks/useToken";

type Props = {
  isPrivate?: boolean;
};

const PrivateRoute: React.FunctionComponent<Props & RouteProps> = ({
  children,
  isPrivate,
  ...rest
}) => {
  const { getToken } = useToken();
  const isAuthenticated = getToken() !== null;
  const isAllowed = isAuthenticated === isPrivate;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAllowed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/login" : "/",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;