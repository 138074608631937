import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Logout from "./pages/Logout/Logout";
import { QueryClientProvider, QueryClient } from "react-query";
import { useClearCache } from "react-clear-cache";
import "./index.scss";
import LazyLoading from "./components/LazyLoading/LazyLoading";

const Login = lazy(() => import("./pages/Login/Login"));
const Register = lazy(() => import("./pages/Register/Register"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Concierge = lazy(() => import("./pages/Concierge/Concierge"));
const ProfileEdit = lazy(() => import("./pages/ProfileEdit/ProfileEdit"));
const DailyBatch = lazy(() => import("./pages/DailyBatch/DailyBatch"));
const Matches = lazy(() => import("./pages/Matches/Matches"));
const ProfileSurvey = lazy(() => import("./pages/ProfileSurvey/ProfileSurvey"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));

const queryClient = new QueryClient();

const App: React.FunctionComponent<{}> = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  return !isLatestVersion ? (
    <div className={"update__container"}>
      <p>Your current App version is out of date, please update:</p>
      <p>
        <button
          onClick={async (e) => {
            e.preventDefault();
            localStorage.clear();
            await emptyCacheStorage();
          }}
        >
          Update version
        </button>
      </p>
    </div>
  ) : (
    <BrowserRouter>
      <Suspense fallback={<LazyLoading />}>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <PrivateRoute isPrivate exact path="/profile/survey">
              <ProfileSurvey />
            </PrivateRoute>
            <PrivateRoute isPrivate exact path="/profile/edit">
              <ProfileEdit />
            </PrivateRoute>
            <PrivateRoute isPrivate exact path="/profile">
              <Profile />
            </PrivateRoute>
            <PrivateRoute isPrivate exact path="/daily-batch">
              <DailyBatch />
            </PrivateRoute>
            <PrivateRoute isPrivate exact path="/matches">
              <Matches />
            </PrivateRoute>
            <PrivateRoute isPrivate exact path={"/concierge"}>
              <Concierge />
            </PrivateRoute>
            <PrivateRoute isPrivate path="/">
              <Redirect to={"/daily-batch"} />
            </PrivateRoute>
          </Switch>
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
