import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/_reset.scss";
import "./index.scss";
import App from "./App";

let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  const currentVh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${currentVh}px`);
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div className={"loading"}>Jade is loading...</div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
